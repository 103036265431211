import React, { useEffect, useState } from 'react'
import Layout from '../components/Layout'
import Hero from '../components/Mobileapp/hero';
import Seo from '../components/Seo';
import PortfolioCom from '../components/Home/PortfolioCom';
import allApiData from '../api';
import SeoheadData from '../siteScriptData';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';



export default function Casestudy() {

  let [loading, setLoading] = useState(true);
  let [caseStudyData, setCaseStudyData] = useState([]);

  let caseDataFunc = async () => {
    let bodyForm = new FormData();
    bodyForm.append('type', 'Game');
    let caseData = await axios.post(`${allApiData.url}/caseStudies`, bodyForm);

    setCaseStudyData(caseData.data.data);
    // return caseData;
  }
  const caseStudyTabData = [
    "Game Development",
    "Application Development",
    "Web Development",
    // "NFT Development"
  ];


  useEffect(() => {
    setTimeout(() => {
      caseDataFunc();
    }, 100)
    if (caseStudyData.length !== undefined) {
      setLoading(false);
    }
    allApiData.aosinit()
  }, []);


  return (
    <Layout>
      <Seo SeoData={SeoheadData.caseStudyPageSeoData}></Seo>

      <Hero title="Case Study" def="Explore our recently deployed projects to learn more about our excellence and development skills. We established values through qualitative work." button_t={true} cta="Get a Quote">
        <div className="hero-img my-lg-5">
          <img src="../assets/img/case-study-twoimg/hero_img/case_study_two.svg" className=" img-fluid" alt="case_study_two" />
        </div>
      </Hero>

      <div className="case-two">
        <PortfolioCom
          heading="Portfolio"
          isTabOn={true}
          onlink={false}
          isImageComp={true}
          isViewBtn={false}
          sectionHeading={false}
          viewurl={"newportfolio"}
          isPagination={false}
          tabData={caseStudyTabData}
          portfolios={caseStudyData}
          portfoliochange={true}
          searchbar={true}
          staticPortFolio={false}
          isLimitPortF={6}
          activeTab="Game Development"
        />
      </div>




    </Layout >
  )
}